const { BASE_URL,DEFAULT_KEY } = require("../../configuration/apiconfig");

export default class ForgotPasswordService {

    key =localStorage.getItem('apiKey')
    // https://demo3.infinitemlmdemo.com/enson/backoffice/login/valid_user
    async checkUsername(formData){
        const response = await fetch(BASE_URL+'auth/valid_user', {     //url
            method: 'POST',                 //method
            headers : {
                'Content-Type'  : 'application/json',
                'api-key'       : DEFAULT_KEY
                                  
            },
            body : JSON.stringify(formData)
        });
        const res = await response.json();
        return res;
    }

    async checkUsermail(formData){
        const response = await fetch(BASE_URL+'auth/valid_user_email', {     //url
            method: 'POST',                 //method
            headers : {
                'Content-Type'  : 'application/json',
                'api-key'       : DEFAULT_KEY
            },
            body : JSON.stringify(formData)
        });
        const res = await response.json();
        return res;
    }


    async forgot_password(formData){
        const response = await fetch(BASE_URL+'auth/password_forget', {     //url
            method: 'POST',                 //method
            headers : {
                'Content-Type'  : 'application/json',
                'api-key'       : DEFAULT_KEY
            },
            body : JSON.stringify(formData)
        });
        const res = await response.json();
        return res;
    }

    async getCaptcha(username){
          //url
        const response = await fetch(BASE_URL+'auth/forget_password?username='+username, {     //url
            method: 'GET',                 //method
            headers : {
                'Content-Type'  : 'application/json',
                'api-key'       : DEFAULT_KEY
        
            }
        })
        if(response.status === 200){
            return response.blob()
        }else{
            const res = await response.json();
            return res;
        }
    }

    async forgot_otp(formData){
        const response = await fetch(BASE_URL+'auth/reset_otp', {     //url
            method: 'POST',                 //method
            headers : {
                'Content-Type'  : 'application/json',
                'api-key'       : this.key?this.key:DEFAULT_KEY
            },
            body : JSON.stringify(formData)
        });
        const res = await response.json();
        return res;
    }
}